import React, {useEffect, useRef, useState} from "react";
import {CreditDisclosure, Header, Spinner, Step, Stepper, UnverifiedPhoneLogin} from "../../components";
import allMessages from "./messages";
import {useLocale, useOnce, useParams, useStateView} from "../../hooks";
import {paths} from "../../routes";
import {paymentPlanView, selectedPlanTypeView, shortLinkRedirectView, frictionlessPageView, accountsInformationView, accountView} from "../../state/views";
import {cn, debugConsole, mixpanel, redirect} from "../../utils";
import PaymentMethodStep from "./Steps/PaymentMethodStep";
import {pplp, paymentPlanBox, legal, disclosureBorder, noScroll} from "./styles.scss";
import {getPaymentPlans} from "../../service/paymentPlan";
import {MIXPANEL_EVENTS, PAYMENT_PLAN_TYPES} from "../../constants";
import {Link} from "react-router-dom";
import {getAccount} from "../../service/account";

function FrictionlessLandingPage() {
  const stepperRef = useRef();
  const {messages} = useLocale(allMessages);
  const [paymentPlan, setPaymentPlan] = useStateView(paymentPlanView);
  const [, setSelectedPlanType] = useStateView(selectedPlanTypeView);
  const [, setComesFromFrictionlessPage] = useStateView(frictionlessPageView);
  const [{verified}] = useStateView(shortLinkRedirectView);
  const {referenceNumber} = useParams();
  const [, setAccount] = useStateView(accountView);

  useOnce(() => mixpanel.track(MIXPANEL_EVENTS.viewFrictionlessLandingPage));

  if (!referenceNumber) {
    redirect(paths.specialOfferError);
  }

  const getAccountInformation = async referenceNumber => {
    const accounts = await getAccount({accountId: referenceNumber, requireAuth: false});

    setAccount(accounts[0]);
  };

  useEffect(() => {
    getAccountInformation(referenceNumber);
    setComesFromFrictionlessPage(true);
    getPaymentPlans(referenceNumber, "frictionlessSplashPage")
      .then(data => {
        const {accountId} = data;

        if ("paymentPlans" in data === false) {
          redirect(paths.specialOfferError);
        }

        const plan = data?.paymentPlans?.[0];

        const lastPaymentAmount = plan?.paymentSchedule[plan.planLength - 1].paymentAmount;
        const {remainingBalance, paymentSchedule} = plan;

        const transformedPaymentSchedule = [
          ...paymentSchedule.map(({paymentId, paymentAmount, paymentStatus, paymentDate}) => ({
            id: paymentId,
            amount: paymentAmount,
            status: paymentStatus,
            date: paymentDate,
          })),
        ];

        const isOnePayment = plan.paymentSchedule.length === 1;

        const planType = isOnePayment
          ? plan.isSettlement
            ? PAYMENT_PLAN_TYPES.settleInFull
            : PAYMENT_PLAN_TYPES.payInFull
          : plan.isSettlement
          ? PAYMENT_PLAN_TYPES.settlementPlan
          : PAYMENT_PLAN_TYPES.paymentPlan;

        const planObject = {
          planType,
          referenceNumber: accountId,
          paymentStartDate: paymentSchedule[0].paymentDate,
          numPayments: plan.planLength,
          paymentFrequency: null,
          paymentSchedule: transformedPaymentSchedule,
          totalPaymentAmount: data?.currentBalance || remainingBalance,
          standardPaymentAmount: plan.paymentSize,
          lastPaymentAmount: plan.planLength > 1 ? lastPaymentAmount : null,
        };

        setPaymentPlan(planObject);
        setSelectedPlanType(planObject.planType);
      })
      .catch(e => {
        debugConsole.error("Error fetching payment plans on Frictionless page", e);
        redirect(paths.specialOfferError);
      });
  }, [referenceNumber]);

  return (
    <>
      <Header hideNav />
      <UnverifiedPhoneLogin />
      <div className={verified ? pplp : cn(pplp, noScroll)}>
        {paymentPlan.referenceNumber ? (
          <Stepper name="Frictionless Landing Page" ref={stepperRef} className={paymentPlanBox}>
            <Step>
              <PaymentMethodStep
                referenceNumber={paymentPlan.referenceNumber}
                paymentPlan={paymentPlan}
                currentBalance={paymentPlan.totalPaymentAmount}
                stepperRef={stepperRef}
                selectedPlanType={paymentPlan.planType}
              />
            </Step>
            <div className={legal}>
              <CreditDisclosure allMessages={allMessages} />
              <p className={disclosureBorder}>
                {messages.miniMiranda}{" "}
                <Link data-mp-name="Know Your Rights" to={paths.disclosures}>
                  {messages.knowYourRights}
                </Link>
              </p>
              <p>{messages.copyrightNotice}</p>
            </div>
          </Stepper>
        ) : (
          <Spinner variant="large" />
        )}
      </div>
    </>
  );
}

export default FrictionlessLandingPage;
