import React from "react";
import {Button, SettlementDetailBox} from "../../../components";
import {PAYMENT_PLAN_TYPES} from "../../../constants";

import {useLocale, useStateView} from "../../../hooks";
import {accountView, paymentPlanView} from "../../../state/views";

import allMessages from "../messages";

import {stepper, centered} from "../../PaymentPlanOfferLandingPage/styles.scss";

import {toggleWrapper, toggle, toggleItem, active} from "../styles.scss";

import {cn} from "../../../utils";
import MoneyIcon from "../../../static/images/icons/MoneyIcon";
import SmallCalendarIcon from "../../../static/images/icons/SmallCalendarIcon";

const StepHeader = ({initialStep = false, step, settlementOffers, referenceNumber, handleToggle = () => {}}) => {
  const {messages} = useLocale(allMessages);
  const [paymentPlan] = useStateView(paymentPlanView);
  const {planType} = paymentPlan;
  const [account] = useStateView(accountView);

  const toggleCallback = selectedPlan => {
    handleToggle(selectedPlan);
  };

  const isSettleInFull = PAYMENT_PLAN_TYPES.settleInFull === planType;
  const isSettlementPlan = PAYMENT_PLAN_TYPES.settlementPlan === planType;

  return (
    <>
      <div className={cn(stepper)}>
        <img src={step} />
      </div>
      <h1 className={centered}>{`${account.accountholderFirstName},`}</h1>

      <h1 className={centered}>{messages.title}</h1>

      <div className={toggleWrapper}>
        <div className={toggle}>
          <Button variant={["transparent"]} className={cn(toggleItem, isSettleInFull ? active : null)} onClick={() => toggleCallback(PAYMENT_PLAN_TYPES.settleInFull)}>
            <MoneyIcon active={isSettleInFull} />
            {messages.payInFullLabel}
          </Button>
          <Button variant={["transparent"]} className={cn(toggleItem, isSettlementPlan ? active : null)} onClick={() => toggleCallback(PAYMENT_PLAN_TYPES.settlementPlan)}>
            <SmallCalendarIcon active={isSettlementPlan} />
            {messages.createPlanLabel}
          </Button>
        </div>
      </div>

      <SettlementDetailBox settlementOffers={settlementOffers} referenceNumber={referenceNumber} isSettlementPlan={isSettlementPlan} />
    </>
  );
};

export default StepHeader;
