import React, {useEffect, useState} from "react";

import {
  accountView,
  activePaymentPlansView,
  documentsView,
  frictionlessPageView,
  menuToggledView,
  paymentHistoryView,
  paymentPlanView,
  redirectView,
  referenceNumbersView,
  selectedReferenceNumberView,
  sessionView,
  shortLinkRedirectView,
  splashPageView,
} from "../../state/views";

import {useLocale, useStateView} from "../../hooks";
import {paths} from "../../routes";

import {getAccount} from "../../service/account";
import {cn, debugConsole, logout, mixpanel, redirect} from "../../utils";
import {clearSessionId} from "../../utils/mixpanel";

import {Link} from "react-router-dom";
import {AccountSelect, LanguageSelect, NavBar, Spinner} from "../";

import {contentSection, contentSectionNav, headerLogo, dropdowns, mobileNavToggle, navSection, wrapper, noOverflow, container, scrollableContent} from "./styles.scss";

import headerLogoSrc from "../../static/images/icons/logoIcon.svg";
import headerLogoMiniSrc from "../../static/images/icons/logoMinIcon.svg";
import {updateUtmParams} from "../../utils/mixpanel";
import {MIXPANEL_EVENTS} from "../../constants";
import Header from "../Header";

// A component that ensures that the user is authorized and that their
// account is not suppressed before showing its child content.
// Having this stuff in component logic seems a little weird to me but
// it does keep everything in one place

// TODO split the nav templating stuff into its own component

let inactivityTimeout = 0;

const AuthBarrier = ({active, hideNav, children, path}) => {
  const [session] = useStateView(sessionView);
  const [account, setAccount, clearAccount] = useStateView(accountView);
  const [, setRedirectUrl] = useStateView(redirectView);
  const [selectedReferenceNumber, setSelectedReferenceNumber] = useStateView(selectedReferenceNumberView);
  const [referenceNumbers] = useStateView(referenceNumbersView);
  const [menuToggled] = useStateView(menuToggledView);

  const [, , clearActivePaymentPlans] = useStateView(activePaymentPlansView);
  const [, , clearPaymentHistory] = useStateView(paymentHistoryView);
  const [, , clearDocuments] = useStateView(documentsView);
  const [, , clearShortLinkFullUrl] = useStateView(shortLinkRedirectView);
  const [, setComesFromSplashPage] = useStateView(splashPageView);
  const [, setComesFromFrictionlessPage] = useStateView(frictionlessPageView);
  const [, , clearPaymentPlan] = useStateView(paymentPlanView);

  const userIsUnauthenticated = !session.authenticated || (session.expires && session.expires < Date.now());
  const selectedAccountIsNotLoaded = selectedReferenceNumber && selectedReferenceNumber !== account?.accountId;

  const [isLoading, setIsLoading] = useState(active && (userIsUnauthenticated || selectedAccountIsNotLoaded));

  const showNavBar = selectedReferenceNumber && !hideNav;

  const {messages} = useLocale();

  updateUtmParams();

  useEffect(() => {
    if (selectedReferenceNumber && active) {
      mixpanel.identify(selectedReferenceNumber);
    }
  }, [selectedReferenceNumber]);

  useEffect(() => {
    if (!active) {
      return;
    }

    if (userIsUnauthenticated === false && session.expires) {
      clearTimeout(inactivityTimeout);
      inactivityTimeout = setTimeout(() => {
        debugConsole.warn("Logging out due to inactivity");
        mixpanel.track(MIXPANEL_EVENTS.inactivityTimeout);
        clearSessionId();
        logout();
      }, session.expires - Date.now());
    }

    if (userIsUnauthenticated) {
      // set redirectUrl to this page so we can return here
      const {href, origin} = document.location;
      const newRedirectUrl = href.substring(origin.length);
      setRedirectUrl(newRedirectUrl === "/" ? "" : newRedirectUrl);
      setSelectedReferenceNumber(null);
      // redirect to login
      mixpanel.reset();
      redirect(paths.login);
    } else if (selectedAccountIsNotLoaded) {
      // clear account-bound state
      clearAccount();
      clearActivePaymentPlans();
      clearPaymentHistory();
      clearDocuments();
      clearShortLinkFullUrl();
      clearPaymentPlan();

      // get new account
      getAccount({accountId: selectedReferenceNumber}).then(accounts => {
        const account = accounts[0];

        if (!account) {
          // TODO error
          debugConsole.error(`Failed to get account ${selectedReferenceNumber}`);
        } else {
          // TODO update the API to just return None instead of this default
          if (account.currentCreditorName === "YOUR CURRENT CREDITOR") {
            account.currentCreditorName = null;
          }
          setAccount(account);
        }

        setComesFromSplashPage(false);
        setComesFromFrictionlessPage(false);
        setIsLoading(false);
        return;
      });
    }
  }, [active, session.authenticated, selectedReferenceNumber]);

  useEffect(() => {
    if (account?.suppressed && path !== paths.accountError) {
      redirect(paths.accountError);
    }
  }, [account?.suppressed, path]);

  useEffect(() => {
    const page = document.getElementById("page");
    menuToggled ? page.classList.add(noOverflow) : page.classList.remove(noOverflow);
  }, [menuToggled]);

  return (
    <>
      {selectedReferenceNumber && <Header hideNav={hideNav} />}
      <div id="page" className={cn(container, showNavBar ? wrapper : null)}>
        {showNavBar && <NavBar />}
        {isLoading && active ? <Spinner variant={"large"} /> : <main>{children}</main>}
      </div>
    </>
  );
};

export default AuthBarrier;
