import React, {useMemo} from "react";

import {BrowserRouter, Routes, Route} from "react-router-dom";
import {ExperimentProvider} from "../Experiment";
import AuthBarrier from "../AuthBarrier";
import RollbarProvider from "../RollbarProvider";
import {routes} from "../../routes";
import "../../styles/base.scss";
import {useMixpanelLinkTracking} from "../../hooks";

const App = () => {
  useMixpanelLinkTracking();

  const mappedRoutes = useMemo(
    () =>
      routes.map(({path, hideNav, component: Component, requireAuth}) => {
        return (
          <Route
            key={`route-${path}`}
            path={path}
            element={
              <AuthBarrier path={path} active={requireAuth} hideNav={hideNav}>
                <Component />
              </AuthBarrier>
            }
          />
        );
      }),
    [routes]
  );

  return (
    <RollbarProvider>
      <ExperimentProvider>
        <BrowserRouter>
          <Routes>{mappedRoutes}</Routes>
        </BrowserRouter>
      </ExperimentProvider>
    </RollbarProvider>
  );
};

export default App;
