import React, {useMemo} from "react";
import {useLocale, useStateView} from "../../hooks";
import {accountView, activePaymentPlansView, paymentMethodView, paymentPlanView} from "../../state/views";

import {CREDITOR_NOT_AVAILABLE, PAYMENT_FREQUENCIES, PAYMENT_INFO_FORMAT, PAYMENT_METHODS, PAYMENT_PLAN_TYPES} from "../../constants";

import allMessages from "./messages.js";

import {paymentInfo} from "./styles.scss";
import {formatCurrency} from "../../utils";
import PaymentSummary from "../PaymentSummary";

import {BankIcon, CalendarIcon, CardIcon, PartialPaymentIcon, PayInFullIcon, PaymentPlanIcon} from "../icons";
import {weekdayString} from "../../utils/date";
import OrdinalNumber from "../OrdinalNumber/index.js";

const OldPaymentInfo = () => {
  const {formatDate, formatMessage, locale, messages} = useLocale(allMessages);

  const [account, accountholderFirstName, accountholderLastName] = useStateView(accountView);
  const [paymentPlan] = useStateView(paymentPlanView);
  const [paymentMethod] = useStateView(paymentMethodView);

  const {clientName} = account;

  const {planType, paymentFrequency, paymentDayOfWeek, paymentStartDate, paymentSchedule, totalPaymentAmount} = paymentPlan;

  const referenceNumber = paymentPlan.referenceNumber || account.accountId;

  const isPromise = !planType;

  const {cardType, consumerName, last4, paymentSource, routingNumber, type} = paymentMethod;

  const paymentFrequencyMessage = useMemo(() => {
    const message = paymentFrequency === PAYMENT_FREQUENCIES.biweekly ? messages.everyOther : messages.every;

    return message[0].toUpperCase() + message.substring(1);
  }, [locale, paymentFrequency]);

  return (
    <table className={paymentInfo}>
      <tbody>
        <tr>
          <th>{messages.originalCreditor}:</th>
          <td>{!clientName || clientName === CREDITOR_NOT_AVAILABLE ? messages.notAvailable : clientName}</td>
        </tr>
        <tr>
          <th>{messages.referenceNumber}:</th>
          <td>{referenceNumber}</td>
        </tr>
        <tr>
          <th>{messages.consumerName}:</th>
          <td>
            {accountholderFirstName} {accountholderLastName}
          </td>
        </tr>
        {routingNumber && (
          <tr>
            <th>{messages.routingNumber}:</th>
            <td>{routingNumber}</td>
          </tr>
        )}
        {type === PAYMENT_METHODS.ach && (
          <tr>
            <th>{messages.accountType}:</th>
            <td>{messages.source[paymentSource]}</td>
          </tr>
        )}
        <tr>
          <th>{messages.paymentMethod}:</th>
          <td>
            {type === PAYMENT_METHODS.ach ? (
              <>
                <BankIcon alt="bank" />
                {messages.bankAccount}
              </>
            ) : (
              <>
                <CardIcon alt="credit or debit card" />
                {cardType}
              </>
            )}{" "}
            <strong>{messages.endingIn}</strong> {last4}
          </td>
        </tr>
        <tr>
          <th>{messages.totalBalance}:</th>
          <td>{formatCurrency(totalPaymentAmount)}</td>
        </tr>
        {isPromise || planType === PAYMENT_PLAN_TYPES.paymentPlan || planType === PAYMENT_PLAN_TYPES.settlementPlan ? (
          <>
            <tr>
              <th>
                <PaymentPlanIcon alt="schedule" />
                {messages[planType || PAYMENT_PLAN_TYPES.paymentPlan]}:
              </th>
              <td>
                <PaymentSummary />
              </td>
            </tr>
            {!isPromise && (
              <tr>
                <th>{messages.paymentFrequency}</th>
                <td>
                  {paymentFrequencyMessage}{" "}
                  {paymentFrequency === PAYMENT_FREQUENCIES.monthly ? (
                    <>
                      <OrdinalNumber num={new Date(paymentStartDate).getUTCDate()} />
                      {" " + messages.ofTheMonth}
                    </>
                  ) : (
                    weekdayString(paymentDayOfWeek, locale)
                  )}
                </td>
              </tr>
            )}
            <tr>
              <th colSpan="2">
                <CalendarIcon alt="calendar" />
                {formatMessage(messages.paymentDate, {singlePayment: paymentSchedule?.length === 1})}:
              </th>
            </tr>
            <tr>
              <td colSpan="2">
                <ol>
                  {paymentSchedule.map(({amount, date}) => (
                    <li>
                      {formatDate(date, PAYMENT_INFO_FORMAT)} - {formatCurrency(amount)}
                    </li>
                  ))}
                </ol>
              </td>
            </tr>
          </>
        ) : (
          <>
            {planType && (
              <tr>
                <th colSpan="2">
                  {planType === PAYMENT_PLAN_TYPES.paymentPartial ? <PartialPaymentIcon alt="hand offering money" /> : <PayInFullIcon alt="hand offering dollar bill" />}
                  {messages[planType]}
                </th>
              </tr>
            )}
            <tr>
              <th>
                <CalendarIcon alt="calendar" />
                {formatMessage(messages.paymentDate, {singlePayment: true})}:
              </th>
              <td>{formatDate(paymentStartDate, PAYMENT_INFO_FORMAT)}</td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
};

export default OldPaymentInfo;
