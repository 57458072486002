import React, {useEffect, useMemo, useRef, useState} from "react";
import {useLocale, useOnce, useParams, useQuiqChat, useStateView} from "../../hooks";
import {
  accountView,
  defaultSpecialPlanView,
  negotiatedPlanView,
  paymentPlanView,
  selectedPlanTypeView,
  settlementOffersView,
  shortLinkRedirectView,
  splashPageView,
} from "../../state/views";
import {getSettlementOffer} from "../../service/landingPage";
import {cn, debugConsole, formatCurrency, getStandardAndLastPayments, mixpanel, numDaysInMonth, paymentPlanFromPlanOption, redirect} from "../../utils";
import {initialState} from "../../state/store";
import {useRollbar} from "@rollbar/react";

import {MIXPANEL_EVENTS, PAYMENT_FREQUENCIES, PAYMENT_PLAN_TYPES} from "../../constants";
import {paths} from "../../routes";
import allMessages from "./messages";

import {Link} from "react-router-dom";
import {
  CreditDisclosure,
  Datepicker,
  OfferHeader,
  OptionList,
  PaymentMethodForm,
  RepaymentDateDropdown,
  SettlementOfferOption,
  Spinner,
  Step,
  Stepper,
  Toggle,
  UnverifiedPhoneLogin,
  Variant,
} from "../../components";

import {pplp, noScroll, legal, disclosureBorder, paymentPlanBox} from "../PaymentPlanOfferLandingPage/styles.scss";

import Header from "../../components/Header";
import InitialStep from "./Steps/InitialStep";
import SelectPaymentStep from "./Steps/SelectPaymentStep";
import SelectFrequencyStep from "./Steps/SelectFrequencyStep";
import {negotiatePaymentPlan} from "../../service/paymentPlan";
import {generatePaymentScheduleFromPaymentPlan} from "../../utils/date";
import {getAccount} from "../../service/account";

const SettlementOfferLandingPage = () => {
  const {messages, formatMessage} = useLocale(allMessages);
  const {referenceNumber} = useParams();
  const rollbar = useRollbar();
  const [frequencyStep, setFrequencyStep] = useState(false);

  const [{accountholderFirstName}, setAccount] = useStateView(accountView);
  const [{verified}] = useStateView(shortLinkRedirectView);
  const [paymentPlan, setPaymentPlan] = useStateView(paymentPlanView);
  const [settlementOffers, setSettlementOffers] = useStateView(settlementOffersView);
  const [selectedPlan, setSelectedPlan] = useStateView(selectedPlanTypeView);
  const [defaultSpecialPlan, setDefaultSpecialPlan] = useStateView(defaultSpecialPlanView);
  const [negotiatedPlan, setNegotiatedPlan] = useStateView(negotiatedPlanView);
  const [, setComesFromSplashPage] = useStateView(splashPageView);

  // load offers on component mount

  useOnce(() => mixpanel.track(MIXPANEL_EVENTS.viewSettlementOfferLandingPage));

  useQuiqChat(
    {
      firstName: accountholderFirstName,
      debtId: referenceNumber,
    },
    verified
  );

  const stepperRef = useRef();

  const getAccountInformation = async referenceNumber => {
    const accounts = await getAccount({accountId: referenceNumber, requireAuth: false});

    setAccount(accounts[0]);
  };

  useEffect(() => {
    getAccountInformation(referenceNumber);
    setComesFromSplashPage(true);
    if (settlementOffers.currentBalance) return;

    getSettlementOffer(referenceNumber)
      .then(response => {
        const {currentBalance, offerAmount, termsOffered} = response;
        const totalPaymentAmount = parseFloat(offerAmount);

        const plans = termsOffered
          .sort() // now the PIF plan variant will always be element 0
          .map(numPayments => {
            const {standardPaymentAmount, lastPaymentAmount} = getStandardAndLastPayments(totalPaymentAmount, numPayments);

            return {
              ...initialState.paymentPlan,
              planType: numPayments === 1 ? PAYMENT_PLAN_TYPES.settleInFull : PAYMENT_PLAN_TYPES.settlementPlan,
              paymentFrequency: PAYMENT_FREQUENCIES.monthly,
              referenceNumber,
              totalPaymentAmount,
              numPayments,
              standardPaymentAmount,
              lastPaymentAmount,
            };
          });

        // Take first plan as default to show
        const defaultPlan = plans[0];
        setSettlementOffers({currentBalance, offerAmount, plans});
        setPaymentPlan({
          ...defaultPlan,
          totalPaymentAmount: currentBalance,
          referenceNumber,
        });
        mixpanel.track(defaultPlan.planType === PAYMENT_PLAN_TYPES.settleInFull ? MIXPANEL_EVENTS.selectSettleInFull : MIXPANEL_EVENTS.selectCreateSettlementPlan);
      })
      .catch(err => {
        rollbar.error("Error fetching settlement plan", err);
        redirect(paths.specialOfferError);
      });

    negotiatePaymentPlan({
      referenceNumber,
      proposedAmount: null,
      proposedFrequency: null,
      planType: PAYMENT_PLAN_TYPES.settlementPlan.toLowerCase(),
    }).then(data => {
      setNegotiatedPlan(data);
      const plan = paymentPlanFromPlanOption(data, PAYMENT_FREQUENCIES.monthly, PAYMENT_PLAN_TYPES.settlementPlan);
      setDefaultSpecialPlan({
        ...plan,
        totalPaymentAmount: data.currentBalance,
        referenceNumber,
      });
    });
  }, [referenceNumber, negotiatedPlan]);

  const [payInFullPlan, settlementPlans] = useMemo(() => {
    const {plans} = settlementOffers;
    return [plans?.[0], plans?.slice(1)];
  }, [settlementOffers]);

  const handleToggle = selectedPlan => {
    const planType = selectedPlan;
    const isSettleInFull = planType === PAYMENT_PLAN_TYPES.settleInFull;

    const plan = isSettleInFull
      ? payInFullPlan
      : {
          ...initialState.paymentPlan,
          planType,
        };

    mixpanel.track(isSettleInFull ? MIXPANEL_EVENTS.selectSettleInFull : MIXPANEL_EVENTS.selectCreateSettlementPlan);

    setPaymentPlan({...plan, referenceNumber});
    setSelectedPlan(planType);
    stepperRef.current.goToStep(0);
  };

  const handleSelectRepaymentDate = paymentStartDate => {
    const selectedDate = new Date(paymentStartDate);
    setPaymentPlan({
      ...paymentPlan,
      paymentStartDate: selectedDate,
      paymentDayOfWeek: selectedDate.getDay(),
    });
  };

  useEffect(() => {
    if (!selectedPlan) {
      setSelectedPlan(PAYMENT_PLAN_TYPES.settleInFull);
    }
  }, []);

  const isPlan = selectedPlan === PAYMENT_PLAN_TYPES.settlementPlan;

  const handleContinue = () => {
    if (!paymentPlan.paymentStartDate) {
      setPaymentPlan({
        ...paymentPlan,
        paymentStartDate: new Date(),
        paymentDayOfWeek: new Date().getDay(),
      });
    }

    if (selectedPlan === PAYMENT_PLAN_TYPES.settleInFull) {
      stepperRef.current.goToStep(2);
    } else {
      setFrequencyStep(true);
      stepperRef.current.goToStep(1);
    }
  };

  const confirmPlan = () => {
    if (paymentPlan.planType !== PAYMENT_PLAN_TYPES.settleInFull) {
      const schedule = generatePaymentScheduleFromPaymentPlan(paymentPlan);
      setPaymentPlan({...paymentPlan, paymentSchedule: schedule});
    }
    redirect(paths.paymentConfirm);
  };

  return (
    <>
      <Header hideNav />
      <UnverifiedPhoneLogin />
      <div className={verified ? pplp : cn(pplp, noScroll)}>
        {defaultSpecialPlan.planType ? (
          <Stepper name="Settlement Offer Landing Page" ref={stepperRef} className={paymentPlanBox}>
            <Step>
              <InitialStep
                accountholderFirstName={accountholderFirstName}
                settlementOffers={settlementOffers}
                referenceNumber={referenceNumber}
                handleToggle={handleToggle}
                handleSelectRepaymentDate={handleSelectRepaymentDate}
                handleContinue={handleContinue}
                selectedDate={{paymentStartDate: new Date()}}
              />
            </Step>

            <Step>{frequencyStep && <SelectFrequencyStep settlementOffers={settlementOffers} referenceNumber={referenceNumber} handleToggle={handleToggle} />}</Step>

            <Step>
              <SelectPaymentStep settlementOffers={settlementOffers} referenceNumber={referenceNumber} handleToggle={handleToggle} onSubmit={() => confirmPlan()} />
            </Step>

            <div className={legal}>
              <CreditDisclosure allMessages={allMessages} />
              <p className={disclosureBorder}>
                {messages.miniMiranda}{" "}
                <Link data-mp-name="Know Your Rights" to={paths.disclosures}>
                  {messages.knowYourRights}
                </Link>
              </p>
              <p>{messages.copyrightNotice}</p>
            </div>
          </Stepper>
        ) : (
          <Spinner variant="large" />
        )}
      </div>
    </>
  );
};

export default SettlementOfferLandingPage;
